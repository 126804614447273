<q-dialog-v2  appendTo="body" [show]="showTerm" maxWidth="65vw" [fullWidth]="true" [restoreFocus]="false" class="model" elevation="0" [disableScrollLock]="false">
  <section q-dialog>
    <div q-dialog-body>
      <qdc-busyable [isBusy]="isBusy">
        <div class="flex flex-col gap-[20px] justify-center items-center">
          <span class="text largest">Terms & Conditions</span>
          <div class="terms-container">
            <div *ngIf="termsAndAgreements.terms?.length" class="terms-text-container" (scroll)="onScroll($event)">
              <div class="html-container" [innerHtml]="termAndAgreementText"></div>
            </div>
          </div>
        </div>
      </qdc-busyable>
    </div>
    <div class="flex gap-[8px] pt-[30px] justify-end" q-dialog-footer>
      <q-button data-testid="decline-term" variant="outline" [disabled]="disableAcceptAndDeclineBtn"
                (click)="onClickDeclineTerm()">Decline</q-button>
      <q-button data-testid="accept-term" variant="fill" color="primary" [disabled]="disableAcceptAndDeclineBtn"
                (click)="onClickAcceptTerm()">Accept</q-button>
    </div>
  </section>
</q-dialog-v2>


<q-dialog-v2 appendTo="body" [show]="showMessage" [restoreFocus]="false" class="model" [disableScrollLock]="false">
  <section q-dialog>
    <div q-dialog-body>
      Please accept the latest Terms and Agreements to continue accessing Qualcomm® Device Cloud.
    </div>
    <div class="flex gap-[8px] pt-[30px] justify-end" q-dialog-footer>
      <q-button data-testid="latest-term-error" variant="fill" color="primary" (click)="onClickDeclineTerm()">Ok</q-button>
    </div>
  </section>
</q-dialog-v2>
