<q-header [title]="title" [actions]="actions" class="top-bar-menu">
  <ng-template #title>
      <div data-testid="go-to-landing" class="clickable flex justify-start items-center content-center gap-[8px] pl-[30px]"
             (click)="routeLanding()">
      <img class="device-cloud-img" src="../../../../assets/images/device-cloud-icon.webp" alt="Qualcomm Device Cloud Icon" />
      <div class="topbar-title">{{ hideTitle ? 'QDC' : strings.view.qdc}}</div>
    </div>
  </ng-template>

  <div class="flex items-center">
    @if (showLoggedInMenuItems) {
      <button q-header-nav-item *ngFor="let item of loggedInRouteItems; let i = index" (click)="route(item.routerLink, i)"
        [active]="activeNavIndex === i">
        {{item.label}}
      </button>
    }
    @else {
      <button q-header-nav-item *ngFor="let item of loggedOutRouteItems; let i = index" (click)="route(item.routerLink, i)"
        [active]="activeNavIndex === i">
        {{item.label}}
      </button>
    }
  </div>

  <ng-template #actions>
    <div class="flex gap-[10px] pr-[40px]">
      <!-- Theme Switch -->
      <q-icon-button *ngIf="darkMode" data-testid='light-mode' [icon]="icons.Moon" size="m"
        qTooltip="Toggle Light Mode" qTooltipPlacement="bottom" qTooltipArrow="true" ariaLabel="dark-theme-switch"
        (click)="toggleDarkMode()">
      </q-icon-button>
      <q-icon-button *ngIf="!darkMode" data-testid='dark-mode' [icon]="icons.SunMedium" size="m"
        qTooltip="Toggle Dark Mode" qTooltipPlacement="bottom" qTooltipArrow="true" ariaLabel="light-theme-switch"
        (click)="toggleDarkMode()">
      </q-icon-button>

      <!-- Help Options -->
      <q-icon-button #retakeTourElement data-testid='help' [icon]="icons.HelpCircle" size="m" ariaLabel="help-button"
        qTooltip="Help" qTooltipPlacement="bottom" qTooltipArrow="true" (click)="helpOptionOverlay.toggle($event)">
      </q-icon-button>

      <!-- Login/Logout Options and User Options -->
      <q-button *ngIf="!userService.isLoggedIn() && !showLogoutButton && !userService.getLoginFailed()"
        style="align-self: center;" dense [startIcon]="icons.User" size="m"
        qTooltipPlacement="left" qTooltipArrow="true" qTooltipId="username-tooltip" (click)="loginAndRouteToHomePage()">
        Login
      </q-button>
      <q-button *ngIf="userService.isLoggedIn() && !showLogoutButton" style="align-self: center;" dense [startIcon]="icons.User" size="m"
        [qTooltip]="this.userService.getFullName()!" qTooltipPlacement="left" qTooltipArrow="true"
        qTooltipId="username-tooltip" (click)="userOptionOverlay.toggle($event)">
        {{hideUsername ? '' : username}}
      </q-button>
      <q-button *ngIf="showLogoutButton" style="align-self: center;" dense [startIcon]="icons.LogOut" size="m"
        (click)="sessionService.logoutRequested()">
        Logout
      </q-button>
    </div>
  </ng-template>
</q-header>

<q-overlay-panel #helpOptionOverlay class="top-bar-overlay" appendTo="host" placement="bottom">
  <ul qOverlayPanelContent class="w-[300px]" style="padding: 10px">
    <span class="text smaller faded" style="padding: 10px 14px 6px 14px">
      Support Resources
    </span>
    <li *ngFor="let option of helpOptions; let i = index" class="flex justify-start items-center content-center"
        (click)="onHelpOptionSelect(option.label)">
      <span style="width: 100%">
        <q-button class="help-option-button" [attr.data-tesid]="'tour-option-' + i">
          {{option.label}}
        </q-button>
      </span>
    </li>
    <ng-container *ngIf="userService.isLoggedIn() && this.userService.isSessionValid()">
      <q-divider class="options-divider"></q-divider>
      <span class="text smaller faded" style="padding: 10px 14px 6px 14px">
        Guided Tours
      </span>
      <li *ngFor="let option of tourOptions; let i = index" class="flex justify-start items-center content-center"
          (click)="option.disabled ? '' : onHelpOptionSelect(option.label)">
        <span [qTooltip]="option.disabled ? option.tooltip : ''" qTooltipArrow="bottom" style="width: 100%">
          <q-button class="help-option-button" [attr.data-tesid]="'tour-option-' + i" [disabled]="option.disabled">
            {{option.label}}
          </q-button>
        </span>
      </li>
    </ng-container>
  </ul>
</q-overlay-panel>

<q-overlay-panel #userOptionOverlay class="top-bar-overlay" appendTo="host" placement="bottom">
  <ul qOverlayPanelContent class="p-[24px]" style="padding: 10px">
    <li *ngFor="let option of userOptions; let i = index" data-testid="logout" class="clickable flex gap-[10px] justify-start items-center content-center min-w-[130px]">
      <q-button [attr.data-tesid]="'user-option-' + i" class="user-option" variant="ghost" [startIcon]="option.value"
        (click)="onUserOptionSelect(option.value)">
        {{option.label}}
      </q-button>
    </li>
  </ul>
</q-overlay-panel>
