import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ICouponRedemption, IRedeemCoupon } from '../interfaces';
import {
  LogService,
  SessionService,
  UserService,
} from 'app/core/services';
import { APIUtility } from '../utils/api-utility';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { QDCApiService } from './qdc-api.service';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RedeemCouponService extends QDCApiService<ICouponRedemption> {
  protected baseUrl: string = APIUtility.isLocalEnv()
    ? APIUtility.getLocalBillingServiceApi()
    : environment.qdcApi;

  constructor(
    http: HttpClient,
    logService: LogService,
    router: Router,
    oauthService: OAuthService,
    protected sessionService: SessionService,
    protected userService: UserService,
  ) {
    super(
      http,
      environment.couponApi,
      logService,
      router,
      oauthService,
      sessionService,
      userService,
    );
  }

  protected /* override */ customErrorHandler(mode: string, url: string, error: HttpErrorResponse): string {
    if (error && error.error) {
      throw error.error;
    }
    return '';
  }

  redeemCoupon(payload: IRedeemCoupon): Observable<ICouponRedemption> {
    return super.submit(payload, `${environment.couponApi}/redeem`);
  }
}
