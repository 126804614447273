// Angular Modules
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

// Routing Modules
import { RouterModule, Routes } from '@angular/router';
import { QuicklinkModule } from 'ngx-quicklink';

// QDC Modules
import { BusyableModule } from '../busyable/busyable.module';

// QUI Modules
import { QButtonModule, QDialogV2Module } from '@qui/angular';

// Services
import { LogService } from 'app/core/services';

// Guards

// TermAcceptanceModule Components
import { TermAcceptanceComponent } from './components/term-acceptance/term-acceptance.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: TermAcceptanceComponent },
];

@NgModule({
  declarations: [
    TermAcceptanceComponent,
  ],
  imports: [
    // Angular Modules
    CommonModule,
    FormsModule,

    // Routing Modules
    QuicklinkModule,
    RouterModule.forChild(routes),

    // QDC Modules
    BusyableModule,

    // QUI Modules
    QButtonModule,
    QDialogV2Module,
  ],
  exports: [
    TermAcceptanceComponent,
    QuicklinkModule,
    RouterModule,
  ],
  providers: [
    LogService,
  ],
})
export class TermAcceptanceModule { }
