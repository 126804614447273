import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

// Please keep fields sorted.

@Injectable()
export class Strings {

  // View Headers and Labels
  view = {
    aiModel: 'AI Model',
    application: 'Application',
    availableNow: 'Available Now',
    bookForLater: 'Book For Later',
    comingSoon: 'Coming Soon',
    devicesBusy: 'Devices Busy',
    job: 'Job',
    jobs: 'Jobs',
    logs: 'Logs',
    publicSshKey: 'SSH Public Key',
    qcommAppSuite: 'Qualcomm® App Suite',
    qcommCertBuild: 'Qualcomm® Certified Build',
    qcommDefaultBuild: 'Default Qualcomm® Build',
    qdc: 'Qualcomm® Device Cloud',
    secureShell: 'Secure Shell',
    session: 'Session',
    sessions: 'Sessions',
    sshMode: 'Screen Mirroring Off',
    sshInfoCompute: 'Remote Desktop or SSH locally to the remote device by adding your SSH Public Key',
    sshInfoNonCompute: 'Connect locally over ADB to the remote device by adding your SSH Public Key',
    stableDiffusion: 'Stable Diffusion',
    unavailable: 'Unavailable',
  };

  // Buttons - keep sorted
  btn = {
    addToCart: 'Add to Cart',
    mergeSave: 'Continue',
    newAutomatedJob: 'New Automated Job',
    newInteractiveSession: 'New Interactive Session',
    purchaseMinutes: 'Purchase Minutes',
    redeemCoupon: 'Redeem Coupon',
    requestFreeMinutes: 'Request Free Minutes',
    unlockFreeMinutes: 'Unlock Free Minutes',
    updateCart: 'Update Cart',
    userSettings: 'User Settings',
  };

  // Entities
  ef = {
    chipset: 'Chipset',
    device: 'Device',
  };

  form = {
    maxLength: 'Field has exceeded the maximum amount of characters',
    pattern: 'Field value is not in the expected format',
    required: 'This field is required',
  }

  // Fields
  fld = {
    chipset: 'Chipset',
    chipsets: 'Chipset(s)',
    completedOn: 'Completed On',
    createdBy: 'Created By',
    createdOn: 'Created On',
    device: 'Device',
    devices: 'Device(s)',
    freeToUse: 'Free to Use',
    id: 'ID',
    jobType: 'Job Type',
    lastModifiedBy: 'Last Modified By',
    lastModifiedOn: 'Last Modified Time',
    minutesAvailable: 'Minutes Available',
    name: 'Name',
    os: 'OS',
    purchaseMinutes: 'Purchase Minutes',
    result: 'Result',
    startedOn: 'Started On',
    state: 'State',
    submittedBy: 'Submitted By',
    submittedOn: 'Submitted On',
    testFramework: 'Test Framework',
    testPackage: 'Test Package',
    testScripts: 'Test Scripts',
    usedMinutes: 'Minutes Used',
  };

  // Tooltips
  ttp = {
    goToLogs: 'Go to Logs',
    logUploadPending: 'Logs Pending',
    noLogs: 'No Logs',
  };

  val = {
    true: 'Yes',
    false: 'No',
    yes: 'Y',
    no: 'N',
    inactive: 'I',
  };

  // Confirmation Dialog Items
  dialog = {
    cancel: 'Cancel',
    checkStackTrace: 'Please click here to see the stack trace.',
    confirm: {
      navigateAway: 'Any changes will be lost by navigating away. Proceed anyway?',
      uploadingNavigateAway: 'By navigating away, any changes and in-progress uploads will be lost. Proceed anyway?',
    },
    ok: 'OK',
    updateTitle: 'Qualcomm® Device Cloud Update Pending',
  };

  info = {
    confidential: 'All information about this device is considered Confidential Information and your use of this information is governed by the Confidentiality provision as set forth in the Qualcomm® Device Cloud/Virtual Test Tool Access Agreement.',
    devicesBusy: 'Please note that the selected device type is busy and the automated job will be queued to the device for processing when devices become available later.',
    qdcTitle: 'Qualcomm® Device Cloud',
  };

  // Documentation Urls
  docs = {
    docsHome: environment.docsUrl,
    contactUs: `${environment.docsUrl}/contact-us`,
  }
}
