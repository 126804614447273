import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {ISku, ISkuInfo } from 'app/core/interfaces';
import { LogService, QDCApiService, SessionService, UserService } from 'app/core/services';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SkuService extends QDCApiService<ISku> {
  constructor(http: HttpClient, logService: LogService, router: Router, oauthService: OAuthService,
    protected sessionService: SessionService, protected userService: UserService) {
    super(http, environment.skusApi, logService, router, oauthService, sessionService, userService);
  }

  protected /* override */ customErrorHandler(mode: string, url: string, error: HttpErrorResponse): string {
    if (error && error.error) {
      return super.getErrorMessage(error.error);
    }
    return '';
  }

  public getSku(id: number): Observable<ISku> {
    return super.getAny(`/${id}`);
  }

  public getSkuInfo(id: number): Observable<ISkuInfo> {
    return super.getAny(`/${id}/info`);
  }
}
